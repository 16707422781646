import axios from 'axios';

// let baseURL = 'https://demo.koloso.app/api/v1';
// let baseURL = 'http://localhost:3000/api/v1';
let baseURL = 'http://134.209.218.22/api/v1';

if (process.env.REACT_APP_API_STAGING) {
  baseURL = process.env.REACT_APP_API_STAGING;
} else if (process.env.REACT_APP_API_PRODUCTION) {
  baseURL = process.env.REACT_APP_API_PRODUCTION;
}

export const COPYRIGHT_TEXT =
  'Copyright ©2024, Koloso Limited. - All rights reserved';

export const instance = axios.create({
  baseURL
});

export const useInterCeptor = (token: string | undefined) => {
  instance.interceptors.request.use(
    (config) => {
      if (token) {
        // config.headers.Authorization = `Bearer ${token}`;
        config.headers.authorization = token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export default instance;

/* eslint-disable react/function-component-definition */
/* eslint-disable no-use-before-define */
import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Card, CardContent, Grid, Typography } from '@mui/material';
import { cyan } from '@mui/material/colors';
import PeopleIcon from '@mui/icons-material/PeopleOutlined';

const TotalPlayers: FC<React.PropsWithChildren<any>> = ({
  className,
  ...rest
}) => {
  return (
    <Card
      style={{
        height: '100%',
        borderRadius: '5px'
      }}
      {...rest}
    >
      <CardContent>
        <Grid container justifyContent="space-between">
          <Grid item md={9}>
            <Typography color="textSecondary" gutterBottom variant="h6">
              Classes
            </Typography>
            <Typography color="textPrimary" variant="h3">
              {0}
            </Typography>
          </Grid>
          <Grid item md={3}>
            <Avatar
              style={{
                backgroundColor: cyan[600],
                height: 46,
                width: 46
              }}
            >
              <PeopleIcon />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

TotalPlayers.propTypes = {
  className: PropTypes.string
};

export default TotalPlayers;

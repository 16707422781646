import { Box } from '@mui/material';
import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import NavBar from './NavBar';
import TopBar from './TopBar';

function SchoolDashboardLayout() {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%'
      }}
    >
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <Box
        sx={{
          display: 'flex',
          flex: '1 1 auto',
          overflow: 'hidden',
          paddingTop: 10
          // paddingLeft: { lg: 256 }
        }}
      >
        <div style={{ display: 'flex', flex: '1 1 auto', overflow: 'hidden' }}>
          <div style={{ flex: '1 1 auto', height: '100%', overflow: 'auto' }}>
            <Outlet />
          </div>
        </div>
      </Box>
    </div>
  );
}

export default SchoolDashboardLayout;

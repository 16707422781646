/* eslint-disable react/function-component-definition */
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Table,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import React from 'react';
import MUIDataTable from 'mui-datatables';
import { Questions } from '../../../types';

const columns = [
  {
    name: '_id',
    label: 'ID',
    options: {
      filter: false,
      display: 'false'
    }
  },
  {
    name: 'question',
    label: 'Question',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'level',
    label: 'level',
    options: {
      filter: true,
      sort: false
    }
  },
  {
    name: 'correctAnswer',
    label: 'Correct Answer',
    options: {
      filter: true,
      sort: false
    }
  }
];
interface Props {
  question: Questions | undefined;
}
const QuestionView: React.FC<Props> = ({ question }) => {
  const data = question?.incorrectAnswers;

  return (
    <Container maxWidth="lg">
      <Grid container sx={{ mt: 1 }} spacing={4}>
        {/**
         * Team Leaner Card
         */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader
              title={
                <Typography color="primary">Question information</Typography>
              }
            />
            <CardContent>
              <Table size="small">
                <TableRow>
                  <TableCell variant="head">Question</TableCell>
                  <TableCell>{question?.question}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell variant="head">Last Name</TableCell>
                  <TableCell>{question?.subject}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Email</TableCell>
                  <TableCell>{question?.correctAnswer}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Level</TableCell>
                  <TableCell>{question?.level}</TableCell>
                </TableRow>
                <br />
                <br />
              </Table>
            </CardContent>
          </Card>
        </Grid>

        {/**
         * Team Name Card
         */}

        <Grid item xs={12} md={12} lg={12} xl={12}>
          <MUIDataTable
            title="Team Member List"
            data={data || []}
            columns={columns}
            options={{
              elevation: 0,
              enableNestedDataAccess: '.',
              responsive: 'simple',
              filterType: 'dropdown',
              selectableRows: 'none'
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default QuestionView;

import * as React from 'react';
import { Container, Grid } from '@mui/material';
import TotalTeams from './TotalTeams';
import TotalLeagues from './TotalLeagues';
import TotalPlayers from './TotalPlayers';
// import PlayerList from '../PlayerList';
import Players from './Players';
// import { OverviewSTudents } from './OverViewStudents';

function SchoolDashboard() {
  return (
    <Container
      style={{
        height: '100%'
      }}
      maxWidth={false}
    >
      <Grid container spacing={3}>
        <Grid item lg={4} sm={6} xl={4} xs={12}>
          <TotalPlayers />
        </Grid>
        <Grid item lg={4} sm={6} xl={4} xs={12}>
          <TotalTeams />
        </Grid>
        <Grid item lg={4} sm={6} xl={4} xs={12}>
          <TotalLeagues />
        </Grid>
        <Grid item lg={12} sm={12} xl={12} xs={12}>
          <Players />
        </Grid>
      </Grid>
      {/* <Grid container>
        <Grid item lg={12} sm={12} xl={12} xs={12}>
          <OverviewSTudents />
        </Grid>
      </Grid> */}
    </Container>
  );
}

export default SchoolDashboard;

const questionDropdownStr = [
  {
    id: 1,
    name: 'Numbers and calculations',
    topics: [
      'Numbers and notation',
      'Number patterns',
      'Place values',
      'Addition',
      'Subtraction',
      'Multiplication',
      'Division',
      'Fractions',
      'Decimals',
      'Combined operations',
      'Factors and multiples',
      'Social and commercial arithmetic',
      'Prime numbers',
      'Approximation and estimation',
      'Ratio and proportion',
      'Real numbers and integers',
      'Number bases',
      'Sequences and serie',
      'Squares and cubes',
      'Percentages',
      'Indices',
      'Standard notation',
      'Square roots'
    ]
  },
  {
    id: 2,
    name: 'Algebra',
    topics: [
      'Sets',
      'Equations, expressions and formulae',
      'Inequations',
      'Plane co-ordinates',
      'Matrices',
      'Simultaneous equations',
      'Graphs of functions',
      'Quadratic equations',
      'Trigonometry',
      'Travel graphs'
    ]
  },
  {
    id: 3,
    name: 'Measurement',
    topics: ['Physical objects', 'Distances', 'Money', 'Time']
  },
  {
    id: 4,
    name: 'Geometry',
    topics: [
      'Two dimensional shapes',
      'Position and direction',
      'Three dimensional shapes',
      'Mensuration',
      'Angles',
      'Circles',
      'Geometrical construction',
      'Bearings',
      'Symmetry',
      'Co-ordinate geometry',
      'Pythagoras theorum',
      'Similarity and congruency',
      'Transformations',
      'Earth geometry',
      'Vectors'
    ]
  },
  {
    id: 5,
    name: 'Statistics',
    topics: [
      'Data',
      'Relations and functions',
      'Graphs and charts',
      'Averages',
      'Probability',
      'Variation',
      'Frequency and dispersion'
    ]
  }
];
export default questionDropdownStr;

/* eslint-disable react/function-component-definition */
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  TextField
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { FunctionComponent } from 'react';
import axios from '../../clientProvider/baseConfig';
import { useNotify } from '../../redux/actions/notifications/notificationActions';

type FormData = {
  questions: FileList;
};

interface Props {
  handleClose: () => void;
}

const UploadDailyQuestion: FunctionComponent<React.PropsWithChildren<Props>> = (
  props
) => {
  const { handleClose } = props;
  const notification = useNotify();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm<FormData>();

  const { mutate, isLoading } = useMutation(
    (data: FormData) => {
      const questions = new FormData();
      // will need to remove the forloop as it is not the best solution thus far
      for (let i = 0; i < data.questions.length; i++) {
        questions.append('questions', data.questions[i]);
      }
      //   questions.append('questions', data.questions[0]);
      return axios.post('/Question/upload_images/', questions);
    },
    {
      onSuccess: (response) => {
        const { message } = response.data;

        if (response.status === 200 || response.status === 201) {
          dispatch(notification({ message, options: { variant: 'success' } }));
        }

        setTimeout(() => handleClose(), 2000);
      },
      onSettled: () => queryClient.invalidateQueries(['Teams']),
      onError: (error: any) => {
        console.log('error', error?.response.data);
        const message = error?.response.data;
        dispatch(
          notification({
            message,
            options: { variant: 'error' }
          })
        );
      }
    }
  );

  const onSubmit = (data: FormData) => {
    const upload = {
      ...data
    };
    mutate(upload);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent>
        <TextField
          {...register('questions')}
          type="file"
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
          inputProps={{ multiple: true }}
        />
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            startIcon={
              isLoading ? <CircularProgress color="inherit" size={26} /> : null
            }
            type="submit"
            disabled={isLoading}
          >
            Upload
          </Button>
          <Button variant="outlined" onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </DialogContent>
    </form>
  );
};

export default UploadDailyQuestion;

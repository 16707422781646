import BackArrowIcon from '@mui/icons-material/ArrowBack';
import { Stack, Typography, Box } from '@mui/material';
import React, { ReactNode } from 'react';

type props = {
  pageTitle: string;
  rightNodes?: ReactNode;
  showTitle?: boolean;
  showBackButton?: boolean;
  page?: ReactNode;
  children?: ReactNode;
};

function AdminPageWrapper({
  pageTitle,
  rightNodes,
  showTitle = true,
  showBackButton = false,
  page,
  children
}: props) {
  return (
    <Box>
      <Stack spacing={3}>
        <Stack direction="row" justifyContent="space-between">
          <Stack spacing={1}>
            <Box sx={{ display: 'flex' }}>
              {showBackButton && (
                <BackArrowIcon
                  onClick={() => window.history.back()}
                  color="primary"
                  sx={{ fontSize: 40, marginLeft: 3.5 }}
                />
              )}
              {showTitle && (
                <Typography
                  sx={{
                    marginTop: 1,
                    marginLeft: showBackButton || rightNodes ? 2 : 3.5,
                    marginBottom: 5
                  }}
                  variant="h4"
                >
                  {pageTitle}
                </Typography>
              )}
            </Box>
          </Stack>
          {rightNodes}
        </Stack>
      </Stack>
      {page}
      {children}
    </Box>
  );
}

export default AdminPageWrapper;

/* eslint-disable import/prefer-default-export */

import MUIDataTable from 'mui-datatables';
import React from 'react';
import { useSelector } from 'react-redux';
import { useGradeColumn } from './grade-columns';
// import { useTeacher } from '../../../clientProvider/queries/teacher-queries';
import { RootState } from '../../../redux/reducers/rootReducer';
import { useGrades } from '../../../clientProvider/queries/grades-queries';

export function GradeList() {
  const columns = useGradeColumn();
  const { user } = useSelector((state: RootState) => state.user);

  const { data } = useGrades({
    variables: { id: user?.school?._id as string }
  });

  return (
    <MUIDataTable
      options={{
        elevation: 0,
        selectableRows: 'none',
        tableBodyHeight: '500px'
      }}
      // title="users"
      columns={columns}
      // @ts-ignore
      data={data?.grades}
    />
  );
}

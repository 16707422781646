import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { axios } from '../../../clientProvider';
import { Questions } from '../../../types';
import Loading from '../../../components/Loading';
import QuestionView from './QuestionView';

const getQuestionById = async (id: string | undefined): Promise<Questions> => {
  const { data } = await axios.get(`/Question/new_question/${id}`);
  return data.data;
};
function QuestionDetails() {
  const { id } = useParams();
  const { data, status } = useQuery(['question', id], () =>
    getQuestionById(id)
  );
  if (status === 'loading') {
    return <Loading size={45} />;
  }
  if (!data) return null;
  return (
    <div>
      <QuestionView question={data} />
    </div>
  );
}

export default QuestionDetails;

/* eslint-disable import/prefer-default-export */
import { createMutation } from 'react-query-kit';
import { AxiosError } from 'axios';
import { axios } from '..';

type Variables = {
  id: string;
  payload: {
    firstName: string;
    lastName: string;
    title: string;
    email: string;
  };
};
export const useAddTeacher = createMutation<any, Variables, AxiosError>({
  mutationFn: async (variables) =>
    axios
      .post(`/School/teachers/new_teacher/${variables.id}`, variables.payload)
      .then((response) => response.data)
});

/* eslint-disable react/function-component-definition */
/* eslint-disable no-use-before-define */
import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Card, CardContent, Grid, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

const TotalApplications: FC<React.PropsWithChildren<any>> = ({ ...rest }) => {
  return (
    <Card
      style={{
        height: '100%',
        borderRadius: '5px'
      }}
      {...rest}
    >
      <CardContent>
        <Grid container justifyContent="space-between">
          <Grid item md={9}>
            <Typography color="textSecondary" gutterBottom variant="h6">
              Teachers
            </Typography>
            <Typography color="textPrimary" variant="h3">
              {0}
            </Typography>
          </Grid>
          <Grid item md={3}>
            <Avatar
              style={{
                backgroundColor: green[600],
                height: 46,
                width: 46
              }}
            >
              <LibraryBooksIcon />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

TotalApplications.propTypes = {
  className: PropTypes.string
};

export default TotalApplications;

/* eslint-disable import/prefer-default-export */
import * as Yup from 'yup';

export const TeacherValidator = Yup.object({
  email: Yup.string()
    .email('Must be a valid email')
    .max(255)
    .required('Email is required'),
  firstName: Yup.string().max(255).required('first name is required'),
  lastName: Yup.string().max(255).required('last name name is required'),
  title: Yup.string().max(255).required('Title is required'),
  grade: Yup.string().max(255).required('Grade is required'),
  class: Yup.string().max(255).required('Class is required')
});

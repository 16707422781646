/* eslint-disable react/react-in-jsx-scope */
import React, { useState } from 'react';
import {
  Container,
  Stack,
  Typography,
  Button,
  Box,
  SvgIcon
} from '@mui/material';
import PlusIcon from '@heroicons/react/24/solid/PlusIcon';
import { StudentList } from './StudentsList';
import AddStudent from './AddStudent';

function Students() {
  const [open, setOpen] = useState(false);
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8
      }}
    >
      <Container maxWidth="xl">
        <Stack spacing={3}>
          <Stack direction="row" justifyContent="space-between" spacing={4}>
            <Stack spacing={1}>
              <Typography variant="h4">Students</Typography>
            </Stack>
            <div>
              <Button
                onClick={() => setOpen((state) => !state)}
                startIcon={
                  <SvgIcon fontSize="small">
                    <PlusIcon />
                  </SvgIcon>
                }
                variant="contained"
              >
                Add Student
              </Button>
            </div>
          </Stack>
          <StudentList />
          <AddStudent open={open} handleClickOpen={setOpen} />
        </Stack>
      </Container>
    </Box>
  );
}

export default Students;

import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Grid,
  Button,
  TextField,
  Link,
  Typography,
  CircularProgress,
  MenuItem,
  InputAdornment,
  IconButton,
  Box
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { axios } from '../../clientProvider';
import { useNotify } from '../../redux/actions/notifications/notificationActions';
import { COPYRIGHT_TEXT } from '../../clientProvider/baseConfig';

const registerAdmin = async (data: Data) => {
  const { data: response } = await axios.post('/Auth/signup', data);
  return response;
};
export interface Data {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  branch: string;
  gender: string;
}

const schema = yup.object().shape({
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password is too short - should be longer than 8 characters.')
    .max(32, 'Password must be less than 32 characters')
});

function SignUp() {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const notification = useNotify();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<Data>({
    mode: 'onChange',
    resolver: yupResolver(schema)
  });
  const { mutate, isLoading } = useMutation(registerAdmin, {
    onSuccess: (data) => {
      const { message } = data;
      dispatch(notification({ message, options: { variant: 'success' } }));
      setTimeout(() => navigate('/'), 1500);
    },
    onError: (error: AxiosError) => {
      dispatch(
        notification({
          message: error.response?.data,
          options: { variant: 'error' }
        })
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(['registered']);
    }
  });
  const onSubmit = (data: Data) => {
    const admin = {
      ...data,
      isActive: true,
      userType: 'Team Lead'
    };
    mutate(admin);
  };
  return (
    <div
      style={{
        padding: 1,
        backgroundColor: '#ffffff',
        height: '100%'
      }}
    >
      <Grid
        style={{
          height: '100%'
        }}
        container
      >
        <Grid
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
          }}
          xs={12}
          sm={6}
          md={4}
          lg={4}
          xl={4}
        >
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: { md: 'center' }
              }}
            >
              <form
                style={{
                  width: 100,
                  marginLeft: 40,
                  marginRight: 40,
                  flexBasis: 700,
                  backgroundColor: '#fff'
                }}
                onSubmit={handleSubmit(onSubmit)}
              >
                <Typography
                  style={{
                    margin: 30,
                    textAlign: 'center'
                  }}
                  variant="h1"
                  color="textSecondary"
                >
                  Sign Up
                </Typography>

                <TextField
                  error={!!errors.firstName}
                  style={{
                    marginTop: 20
                  }}
                  fullWidth
                  size="small"
                  label="First Name"
                  type="text"
                  variant="outlined"
                  {...register('firstName')}
                />
                <TextField
                  error={!!errors.lastName}
                  style={{
                    marginTop: 20
                  }}
                  fullWidth
                  size="small"
                  label="Last Name"
                  type="text"
                  variant="outlined"
                  {...register('lastName')}
                />
                <TextField
                  error={!!errors.email}
                  style={{
                    marginTop: 20
                  }}
                  fullWidth
                  size="small"
                  label="Email address"
                  type="text"
                  variant="outlined"
                  {...register('email')}
                />
                <TextField
                  error={!!errors.password}
                  fullWidth
                  size="small"
                  style={{
                    marginTop: 20
                  }}
                  label="Password"
                  variant="outlined"
                  {...register('password')}
                  helperText={errors.password?.message}
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      select
                      label="Gender"
                      variant="outlined"
                      value={value}
                      onChange={onChange}
                      margin="normal"
                      size="small"
                      fullWidth
                    >
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                    </TextField>
                  )}
                  rules={{ required: true }}
                  name="gender"
                  control={control}
                />

                <Button
                  style={{
                    marginTop: 20
                  }}
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  startIcon={
                    isLoading ? (
                      <CircularProgress color="inherit" size={26} />
                    ) : null
                  }
                >
                  Sign Up
                </Button>
                <Typography
                  color="textSecondary"
                  variant="body1"
                  style={{
                    marginTop: 20
                  }}
                >
                  Have an account?{' '}
                  <Link component={RouterLink} to="/signin" variant="h6">
                    Sign in
                  </Link>
                </Typography>
              </form>
            </Box>
          </div>
          <Typography
            style={{
              margin: 30,
              textAlign: 'center'
            }}
            variant="h6"
            color="textSecondary"
          >
            {COPYRIGHT_TEXT}
          </Typography>
        </Grid>
        <Grid
          sx={{
            display: {
              xs: 'none',
              sm: 'none',
              md: 'block',
              lg: 'block',
              xl: 'block'
            }
          }}
          item
          xs={12}
          sm={6}
          md={8}
          lg={8}
          xl={8}
        >
          <div
            style={{
              backgroundColor: '#fff',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundImage: 'url(/images/Svg/nice.png)',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center'
            }}
          >
            <div
              style={{
                textAlign: 'center',
                flexBasis: '600px'
              }}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default SignUp;

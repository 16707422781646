import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  MenuItem
  // MenuItem
} from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { nanoid } from '@reduxjs/toolkit';
import TextField from '@mui/material/TextField';

import { useFormik } from 'formik';
// import { useQueryClient } from '@tanstack/react-query';
// import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import { TeacherValidator } from '../../../validators/teacher-validator';
import { useTeacher } from '../../../clientProvider/queries/teacher-queries';
import { RootState } from '../../../redux/reducers/rootReducer';
import { useAddTeacher } from '../../../clientProvider/mutations/teacher-mutations';

type AddLessonFormProps = {
  open: boolean;
  handleClickOpen: Dispatch<SetStateAction<boolean>>;
};
function AddTeacher({ open, handleClickOpen }: AddLessonFormProps) {
  const { user } = useSelector((state: RootState) => state.user);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const teacherQuery = useTeacher({
    variables: { id: user?.school?._id as string }
  });
  const { isLoading, mutateAsync } = useAddTeacher({
    onSuccess: () => {
      enqueueSnackbar('Teacher added', { variant: 'success' });
      setTimeout(() => handleClick(), 1500);
    },
    onError: (error) =>
      enqueueSnackbar(error?.response?.data.message, { variant: 'error' }),
    onSettled: () => queryClient.invalidateQueries(teacherQuery.queryKey)
  });
  const handleClick = () => {
    handleClickOpen((state) => !state);
  };
  const {
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    values,
    isValid
  } = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      title: '',
      email: '',
      grade: '',
      class: ''
    },
    onSubmit: async (data, { resetForm }) => {
      const value = {
        id: user?.school?._id as string,
        payload: data
      };
      await mutateAsync(value);

      resetForm();
    },
    validationSchema: TeacherValidator,
    validateOnChange: true
  });
  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClick}>
      <form noValidate onSubmit={handleSubmit}>
        <DialogTitle>Add New Teacher</DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              m: 'auto'
            }}
          >
            <TextField
              margin="normal"
              size="small"
              fullWidth
              label="First name"
              variant="filled"
              name="firstName"
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
              value={values.firstName}
            />
            <TextField
              margin="normal"
              size="small"
              fullWidth
              label="Last name"
              variant="filled"
              name="lastName"
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
              value={values.lastName}
            />
            <TextField
              margin="normal"
              size="small"
              fullWidth
              label="Email"
              variant="filled"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!(touched.email && errors.email)}
              helperText={touched.email && errors.email}
              value={values.email}
            />
            <TextField
              margin="normal"
              size="small"
              fullWidth
              label="Title"
              variant="filled"
              name="title"
              onChange={handleChange}
              onBlur={handleBlur}
              select
              error={!!(touched.title && errors.title)}
              helperText={touched.title && errors.title}
              value={values.title}
            >
              {titles.map((title) => (
                <MenuItem key={nanoid(6)} value={title}>
                  {title}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              margin="normal"
              size="small"
              fullWidth
              label="Grade"
              variant="filled"
              name="grade"
              onChange={handleChange}
              onBlur={handleBlur}
              select
              error={!!(touched.grade && errors.grade)}
              helperText={touched.grade && errors.grade}
              value={values.grade}
            >
              {titles.map((title) => (
                <MenuItem key={nanoid(6)} value={title}>
                  {title}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              margin="normal"
              size="small"
              fullWidth
              label="Class"
              variant="filled"
              name="class"
              onChange={handleChange}
              onBlur={handleBlur}
              select
              error={!!(touched.class && errors.class)}
              helperText={touched.class && errors.class}
              value={values.class}
            >
              {titles.map((title) => (
                <MenuItem key={nanoid(6)} value={title}>
                  {title}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={isLoading}
            type="submit"
            variant="contained"
            color="primary"
            disabled={!isValid}
          >
            Add
          </LoadingButton>
          <Button onClick={handleClick}>Close</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const titles = ['Mr', 'Mrs', 'Ms', 'Miss'];

export const role = [
  'Principal',
  'Vice Principal',
  'Headmaster',
  'Headmistress',
  'Teacher',
  'Headteacher',
  'Deputy headteacher',
  'Head of a math teacher',
  'Math coordinator'
];

export default AddTeacher;

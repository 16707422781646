import React from 'react';
import { Container } from '@mui/material';
import UploadCurriculumView from './components/UploadCurriculumView';
import CurriculumListView from './components/Toolbar';

function CurriculumView() {
  return (
    <Container maxWidth={false}>
      <UploadCurriculumView />
      <CurriculumListView />
    </Container>
  );
}
export default CurriculumView;

/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/function-component-definition */
import * as React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Paper, Typography } from '@mui/material';
import axios from '../../../../clientProvider/baseConfig';
import Loading from '../../../../components/Loading';

const getSchool = async (schoolId: string | undefined): Promise<any[]> => {
  const { data } = await axios.get(
    `/School/students/view_students/${schoolId}`
  );
  console.log('data', data);
  return data.students;
};

const StudentList: React.FC<React.PropsWithChildren<unknown>> = () => {
  const params = useParams();
  const { data, isLoading } = useQuery(['Student'], () => getSchool(params.id));

  if (isLoading) {
    return <Loading size={40} />;
  }

  return (
    <Paper
      elevation={1}
      sx={{
        padding: 2,
        flex: '1 1 auto',
        marginLeft: 2,
        background: '#fff'
      }}
    >
      <Typography variant="h6" color="text.primary" gutterBottom>
        Total Students
      </Typography>
      <Typography variant="h4" color="textPrimary">
        {data?.length}
      </Typography>
    </Paper>
  );
};

export default StudentList;

import React, { useEffect } from 'react';
import CurriculumIcon from '@mui/icons-material/ReceiptLong';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CustomModal } from '../../../../components/Modal';
import UploadCurriculumModal from '../UploadCurriculumModal';
import useCurriculum from '../../../../hooks/use-curriculum';

function CurriculumListView() {
  const {
    uploadCurriculumVisibility,
    openUploadCurriculumModal,
    closeUploadCurriculumModal,
    fetchCurriculums,
    curriculums
  } = useCurriculum();

  useEffect(() => {
    fetchCurriculums();
  }, []);

  return (
    <>
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Available Curriculum
          </Typography>

          <Box>
            {curriculums.map((c) => (
              <>
                <Box
                  sx={{ marginTop: 3, display: 'flex', flexDirection: 'row' }}
                >
                  <span>
                    <CurriculumIcon />
                  </span>

                  <Typography
                    sx={{ marginLeft: 0.3, marginTop: 0.2 }}
                    variant="h5"
                    component="div"
                  >
                    {c.curriculum}
                  </Typography>
                </Box>
                <Typography sx={{ fontSize: 12 }} color="text.secondary">
                  {new Date(c.lastUpdatedAt).toDateString()}
                </Typography>
              </>
            ))}
          </Box>
        </CardContent>
        <CardActions>
          <Button onClick={openUploadCurriculumModal} size="small">
            Upload Curriculum{' '}
          </Button>
        </CardActions>
      </Card>

      <div style={{ marginTop: '10px' }}>
        <CustomModal
          title="Upload Curriculum"
          open={uploadCurriculumVisibility}
          maxWidth="sm"
          handleClose={closeUploadCurriculumModal}
        >
          <UploadCurriculumModal handleClose={closeUploadCurriculumModal} />
        </CustomModal>

        {/* This component renders an input element with a search icon to allow users to enter search queries */}
      </div>
    </>
  );
}

export default CurriculumListView;

/* eslint-disable react/function-component-definition */
import { CardHeader, IconButton, Typography } from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

interface ModalTitleProps {
  subheader: string;
  handleClose?: () => void;
}

const ModalTitle: React.FC<React.PropsWithChildren<ModalTitleProps>> = (
  props
) => {
  const { children, subheader, handleClose, ...other } = props;

  return (
    <MuiDialogTitle
      style={{
        margin: 0,
        padding: '20px'
      }}
      {...other}
    >
      <CardHeader
        title={<Typography variant="h4">{children}</Typography>}
        subheader={subheader}
      />

      {handleClose ? (
        <IconButton
          aria-label="close"
          sx={{
            position: 'absolute',
            right: '10px',
            top: '10px',
            color: '#f7f7f7'
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

export default ModalTitle;

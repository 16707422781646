/* eslint-disable no-constant-condition */
import React from 'react';
import {
  Grid,
  Button,
  TextField,
  Typography,
  CircularProgress,
  Link,
  InputAdornment,
  IconButton,
  Box
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { axios } from '../../../clientProvider';
import { useNotify } from '../../../redux/actions/notifications/notificationActions';
import { loginSuccess } from '../../../redux/actions/userActions/userActions';
import { COPYRIGHT_TEXT } from '../../../clientProvider/baseConfig';

const grantAccess = async (user: Inputs) => {
  const { data: response } = await axios.post('/Auth/login', user);
  return response;
};

type Inputs = { email: string; password: string };

const schema = yup.object().shape({
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password is too short - should be longer than 8 characters.')
    .max(32, 'Password must be less than 32 characters')
});

function SignIn() {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const enqueueSnackbar = useNotify();
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<Inputs>({
    mode: 'onChange',
    resolver: yupResolver(schema)
  });
  const { mutate, isLoading } = useMutation(grantAccess, {
    onSuccess: (response) => {
      const { message, data } = response;
      dispatch(loginSuccess(response.data));
      axios.defaults.headers = { authorization: response.data.token };
      dispatch(enqueueSnackbar({ message, options: { variant: 'success' } }));
      setTimeout(() => {
        if (data.userType === 'Admin') {
          navigate('/app/dashboard');
        } else if (data.userType === 'Editor') {
          navigate('/app/dashboard');
        } else if (data.userType === 'Teacher') {
          navigate('/school/dashboard');
        }
      }, 1500);
    },
    onError: (error: AxiosError) => {
      dispatch(
        enqueueSnackbar({
          message: error.response?.data.message,
          options: { variant: 'error' }
        })
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(['login']);
    }
  });
  const onSubmit = (data: Inputs) => {
    const user = {
      ...data
    };
    mutate(user);
  };
  return (
    <div
      style={{
        padding: 0,
        backgroundColor: '#ffffff',
        height: '100%'
      }}
    >
      <Grid
        style={{
          height: '100%'
        }}
        container
      >
        <Grid
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
          }}
          item
          xs={12}
          sm={5}
          md={5}
          lg={5}
          xl={5}
        >
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: { md: 'center' }
              }}
            >
              <form
                style={{
                  width: 100,
                  marginLeft: 40,
                  marginRight: 40,
                  paddingBottom: 125,
                  flexBasis: 700
                }}
                onSubmit={handleSubmit(onSubmit)}
              >
                <Typography
                  style={{
                    margin: 30,
                    textAlign: 'center'
                  }}
                  variant="h1"
                  color="textSecondary"
                >
                  Sign in
                </Typography>
                <Typography
                  style={{
                    margin: 30,
                    textAlign: 'center'
                  }}
                  variant="h5"
                  color="textSecondary"
                >
                  Welcome! Please login to continue.
                </Typography>

                <TextField
                  error={!!errors.email}
                  style={{
                    marginTop: 20
                  }}
                  fullWidth
                  label="Email address"
                  type="text"
                  variant="outlined"
                  {...register('email')}
                  data-testid="email"
                />
                <TextField
                  error={!!errors.password}
                  fullWidth
                  style={{
                    marginTop: 20
                  }}
                  label="Password"
                  variant="outlined"
                  {...register('password')}
                  type={showPassword ? 'text' : 'password'}
                  helperText={errors.password?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  data-testid="password"
                />
                <Button
                  style={{
                    margin: '20px 0'
                  }}
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  disabled={isLoading}
                  startIcon={
                    isLoading ? (
                      <CircularProgress color="inherit" size={25} />
                    ) : null
                  }
                >
                  Sign In
                </Button>
                <Grid container>
                  <Grid item xs={6}>
                    <Link
                      href="/forgot-password"
                      variant="body2"
                      style={{ color: '#172B4D' }}
                    >
                      Forgot password?
                    </Link>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </div>
          <Typography
            style={{
              margin: 30,
              textAlign: 'center'
            }}
            variant="h6"
            color="textSecondary"
          >
            {COPYRIGHT_TEXT}
          </Typography>
        </Grid>
        <Grid
          sx={{
            display: {
              xs: 'none',
              sm: 'none',
              md: 'block',
              lg: 'block',
              xl: 'block'
            }
          }}
          item
          xs={12}
          sm={7}
          md={7}
          lg={7}
          xl={7}
        >
          <div
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#E85D04',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center'
            }}
          >
            <div
              style={{
                height: '80%',
                width: '80%',
                // top: '10.88%',
                // position: 'absolute',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundImage: 'url(/images/APPACUS_white.png)',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
              }}
            />
            {/* <div
              style={{
                height: '283px',
                width: '283px',
                top: '36.83%',
                position: 'absolute',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundImage: 'url(/images/APPACUS_icon_color.png)',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
              }}
            /> */}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default SignIn;

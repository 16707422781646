/* eslint-disable react/function-component-definition */
import React from 'react';
import { AppBar, Toolbar } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Logo from '../../components/Logo';

interface Props {
  className?: string;
}
const TopBar: React.FC<Props> = ({ className, ...rest }) => {
  return (
    <AppBar elevation={1} {...rest} sx={{ backgroundColor: '#fff' }}>
      <Toolbar sx={{ height: 64 }}>
        <RouterLink to="/app/dashoard">
          <Logo />
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;

import React from 'react';
import {
  Grid,
  Button,
  TextField,
  Typography,
  CircularProgress
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { axios } from '../../clientProvider';
import { useNotify } from '../../redux/actions/notifications/notificationActions';
// import { loginSuccess } from '../../redux/actions/userActions/userActions';

const grantAccess = async (data: Inputs) => {
  const { data: response } = await axios.post('/Auth/forgot_password', data);
  return response;
};
interface Inputs {
  email: string;
}
const emailRegExp = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,3}$/i;
const schema = yup.object().shape({
  email: yup
    .string()
    .matches(emailRegExp, 'Enter a valid email')
    .required('Email is required')
});
function ForgotPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const enqueueSnackbar = useNotify();
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<Inputs>({
    mode: 'onChange',
    resolver: yupResolver(schema)
  });
  const { mutate, isLoading } = useMutation(grantAccess, {
    onSuccess: (response) => {
      const { message } = response;
      dispatch(enqueueSnackbar({ message, options: { variant: 'success' } }));
      setTimeout(() => navigate('/'), 1000);
    },
    onError: (error: AxiosError) => {
      dispatch(
        enqueueSnackbar({
          message: error.response?.data,
          options: { variant: 'error' }
        })
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(['forgotPassword']);
    }
  });
  const onSubmit = (data: Inputs) => {
    const user = {
      ...data
    };
    mutate(user);
  };
  return (
    <div style={{ padding: 1, backgroundColor: '#ffffff', height: '100%' }}>
      <Grid sx={{ height: '100%' }} container>
        <Grid
          sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
          item
          xs={12}
          sm={5}
          md={5}
          lg={5}
          xl={5}
        >
          <div
            style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                paddingTop: '200px',
                paddingBottom: '1px'
              }}
            />
            <div
              style={{
                flexGrow: 1,
                display: 'flex'
              }}
            >
              <form
                style={{
                  width: 100,
                  paddingLeft: 40,
                  paddingRight: 40,
                  paddingBottom: 125,
                  flexBasis: 700
                }}
                onSubmit={handleSubmit(onSubmit)}
              >
                <Typography
                  sx={{ marginTop: '20px' }}
                  style={{
                    textAlign: 'center',
                    color: '#546e7a',
                    fontSize: '32px'
                  }}
                  variant="h2"
                >
                  Forgot your Password?
                </Typography>
                <Typography
                  color="textSecondary"
                  style={{
                    textAlign: 'center',
                    margin: '10px 0px 20px',
                    fontSize: '1.10em'
                  }}
                  gutterBottom
                >
                  Enter your email address below
                </Typography>

                <TextField
                  error={Boolean(errors.email?.message)}
                  sx={{ marginTop: '2px' }}
                  helperText={errors.email?.message}
                  fullWidth
                  label="Email address"
                  type="email"
                  variant="outlined"
                  id="email"
                  {...register('email')}
                />

                <Button
                  sx={{ margin: '20px 0px' }}
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  disabled={isLoading}
                  startIcon={
                    isLoading ? (
                      <CircularProgress color="inherit" size={25} />
                    ) : null
                  }
                >
                  Submit
                </Button>
              </form>
            </div>
          </div>
        </Grid>

        {/* [theme.breakpoints.down('sm')]: {
      display: 'none' } */}
        <Grid
          sx={{
            display: {
              xs: 'none',
              sm: 'none',
              md: 'block',
              lg: 'block',
              xl: 'block'
            }
          }}
          item
          xs={12}
          sm={7}
          md={7}
          lg={7}
          xl={7}
        >
          <div
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#E85D04',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center'
            }}
          >
            <div
              style={{
                height: '80%',
                width: '80%',
                // top: '10.88%',
                // position: 'absolute',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundImage: 'url(/images/APPACUS_white.png)',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
              }}
            />
            {/* <div
              style={{
                height: '283px',
                width: '283px',
                top: '36.83%',
                position: 'absolute',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundImage: 'url(/images/APPACUS_icon_color.png)',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
              }}
            /> */}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default ForgotPassword;

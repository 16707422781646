/* eslint-disable no-use-before-define */
/* eslint-disable import/extensions */
import { Box, Container } from '@mui/material';
import * as React from 'react';

function NotFoundView() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="center"
      sx={{
        backgroundColor: '#ffffff'
      }}
    >
      <Container maxWidth="md">
        <Box textAlign="center">
          <img
            alt="Under development"
            style={{
              marginTop: 50,
              display: 'inline-block',
              maxWidth: '100%',
              width: 560
            }}
            src="/images/Svg/404.png"
          />
        </Box>
      </Container>
    </Box>
  );
}

export default NotFoundView;

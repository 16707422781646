/* eslint-disable react/function-component-definition */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
// import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
// import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import { Box, Divider, Drawer, Hidden } from '@mui/material';
import NavItem from './NavItem';

export const items = [
  {
    href: '/school/dashboard',
    icon: DashboardOutlinedIcon,
    title: 'Dashboard'
  },

  {
    href: '/school/grades',
    icon: SupervisorAccountOutlinedIcon,
    title: 'Grades'
  },

  {
    href: '/school/classes',
    icon: QuestionAnswerIcon,
    title: 'Classes'
  },
  {
    href: '/school/teachers',
    icon: ManageAccountsOutlinedIcon,
    title: 'Teachers'
  },
  {
    href: '/school/students',
    icon: ManageAccountsOutlinedIcon,
    title: 'Students'
  }
  //   {
  //     href: '/app/leagues',
  //     icon: SupervisorAccountOutlinedIcon,
  //     title: 'Leagues'
  //   },
  //   {
  //     href: '/app/leaderboards',
  //     icon: LeaderboardIcon,
  //     title: 'Leaderboard'
  //   },
  // {
  //   href: '/school/account',
  //   icon: AccountCircleOutlinedIcon,
  //   title: 'Account'
  // }
];

const logout = [
  {
    href: '/logout',
    icon: ExitToAppOutlinedIcon,
    title: 'Logout'
  }
];

interface Props {
  onMobileClose: () => void;
  openMobile: boolean;
}

const NavBar: React.FC<Props> = ({ onMobileClose, openMobile }) => {
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      sx={{ backgroundColor: '#ffffff', border: 'none' }}
    >
      <Box p={2}>
        <NavItem items={items} />
      </Box>
      <br />
      <br />
      <br />
      <br />
      <Divider />
      <Box p={2}>
        <NavItem items={logout} />
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          sx={{ width: 256 }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          sx={{
            width: 256,
            top: 68,
            height: 'calc(100% - 64px)',
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: 256,
              top: 68
            }
          }}
          open
          variant="permanent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default NavBar;

/* eslint-disable import/prefer-default-export */

import MUIDataTable from 'mui-datatables';
import React from 'react';
import { useSelector } from 'react-redux';
import { useTeachersColumn } from './teachers-columns';
import { useTeacher } from '../../../clientProvider/queries/teacher-queries';
import { RootState } from '../../../redux/reducers/rootReducer';

export function TeachersList() {
  const columns = useTeachersColumn();
  const { user } = useSelector((state: RootState) => state.user);

  const { data } = useTeacher({
    variables: { id: user?.school?._id as string }
  });

  return (
    <MUIDataTable
      options={{
        elevation: 0,
        selectableRows: 'none',
        tableBodyHeight: '500px'
      }}
      // title="users"
      columns={columns}
      data={data?.data}
    />
  );
}

/* eslint-disable import/prefer-default-export */
import * as Yup from 'yup';

export const StudentValidator = Yup.object({
  email: Yup.string()
    .email('Must be a valid email')
    .max(255)
    .required('Email is required'),
  firstName: Yup.string().max(255).required('first name is required'),
  lastName: Yup.string().max(255).required('last name name is required'),
  gender: Yup.string().max(255).required('Gender is required'),
  location: Yup.string().max(255).required('Location is required'),
  // dob: Yup.string().max(255).required('Date of birth is required'),
  level: Yup.string().max(255).required('Level is required')
});

/* eslint-disable react/function-component-definition */
import { Container, Grid, Paper, Skeleton, Typography } from '@mui/material';
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { School } from '../../../../types';
import { axios } from '../../../../clientProvider';
import StudentList from './StudentList';
import AdminPageWrapper from '../../../../layouts/AdminLayout/admin-page-wrapper';

const getSchool = async (id: string | undefined): Promise<School> => {
  const { data: res } = await axios.get(`/School/view_school/${id}`);

  return res.school;
};

const SchoolView: React.FC<School> = () => {
  const { id } = useParams();
  const { data } = useQuery(['SchoolView', id], () => getSchool(id));

  const page = (
    <Container maxWidth="lg">
      <Grid container sx={{ mt: 1 }} spacing={3}>
        <Grid item lg={4} sm={6} xl={4} xs={12}>
          <Paper
            elevation={1}
            sx={{
              padding: 2,
              flex: '1 1 auto',
              marginRight: 2,
              background: '#fff'
            }}
          >
            <Typography variant="h6" color="text.primary" gutterBottom>
              Total Teachers
            </Typography>
            <Typography variant="h4" color="textPrimary">
              {data?.teachers.length}
            </Typography>
          </Paper>
        </Grid>
        <Grid item lg={4} sm={6} xl={4} xs={12}>
          <StudentList />
        </Grid>

        <Grid item xs={12} md={12} lg={12} xl={12}>
          <Paper
            sx={{
              marginTop: 1,
              marginBottom: 5,
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              padding: 2
            }}
          >
            <Typography variant="h4" color="primary" sx={{ marginBottom: 2 }}>
              School Details
            </Typography>
            {data ? (
              <>
                {data.levelOfEducation && (
                  <Typography>
                    levelOfEducation: {data.levelOfEducation}
                  </Typography>
                )}
                {data.country && (
                  <Typography color="text.primary">
                    Country: {data.country}
                  </Typography>
                )}
                {data.city && <Typography>City: {data.city}</Typography>}
                {data.motto && <Typography>Motto: {data.motto}</Typography>}
                {data.website && (
                  <Typography>Website: {data.website}</Typography>
                )}
              </>
            ) : (
              <>
                <Skeleton
                  variant="text"
                  width="40%"
                  height={20}
                  animation="wave"
                />
                <Skeleton
                  variant="text"
                  width="30%"
                  height={20}
                  animation="wave"
                />
                <Skeleton
                  variant="text"
                  width="50%"
                  height={20}
                  animation="wave"
                />
                <Skeleton
                  variant="text"
                  width="60%"
                  height={20}
                  animation="wave"
                />
              </>
            )}
          </Paper>

          <Grid container sx={{ mt: 1 }} spacing={3}>
            <Grid item lg={6} sm={6} xl={6} xs={12}>
              <Paper
                sx={{
                  marginTop: 1,
                  marginBottom: 5,
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  padding: 2
                }}
              >
                <Typography
                  variant="h4"
                  color="primary"
                  sx={{ marginBottom: 2 }}
                >
                  Point Of Contact
                </Typography>
                {data ? (
                  <>
                    <Typography>
                      Name: {data?.admin?.firstName} {data?.admin?.lastName}
                    </Typography>
                    <Typography>Phone: {data?.admin?.phone}</Typography>
                    <Typography>Email: {data?.admin?.email}</Typography>
                  </>
                ) : (
                  <>
                    <Skeleton
                      width={200}
                      height={20}
                      animation="wave"
                      sx={{ marginBottom: 1 }}
                    />
                    <Skeleton
                      width={150}
                      height={20}
                      animation="wave"
                      sx={{ marginBottom: 1 }}
                    />
                    <Skeleton
                      width={180}
                      height={20}
                      animation="wave"
                      sx={{ marginBottom: 1 }}
                    />
                  </>
                )}
              </Paper>
            </Grid>
            <Grid item lg={6} sm={6} xl={6} xs={12}>
              <Paper
                sx={{
                  marginTop: 1,
                  marginBottom: 5,
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  padding: 2
                }}
              >
                <Typography
                  variant="h4"
                  color="primary"
                  sx={{ marginBottom: 2 }}
                >
                  Principal
                </Typography>
                {data ? (
                  <>
                    <Typography>
                      Name: {data?.admin?.firstName} {data?.admin?.lastName}
                    </Typography>
                    <Typography>Phone: {data?.admin?.phone}</Typography>
                    <Typography>Email: {data?.admin?.email}</Typography>
                  </>
                ) : (
                  <>
                    <Skeleton
                      width={200}
                      height={20}
                      animation="wave"
                      sx={{ marginBottom: 1 }}
                    />
                    <Skeleton
                      width={150}
                      height={20}
                      animation="wave"
                      sx={{ marginBottom: 1 }}
                    />
                    <Skeleton
                      width={180}
                      height={20}
                      animation="wave"
                      sx={{ marginBottom: 1 }}
                    />
                  </>
                )}
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );

  return (
    <AdminPageWrapper
      pageTitle={data?.name || 'View school'}
      showBackButton
      page={page}
    />
  );
};

export default SchoolView;

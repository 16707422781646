/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-wrap-multilines */
import React, { FunctionComponent, useEffect } from 'react';
import {
  Button,
  DialogActions,
  // MenuItem,
  TextField,
  Container,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  MenuItem
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../../../clientProvider/baseConfig';
import questionDropdownStr from '../../../components/questionDropdownStr';
// @ts-ignore
import { useNotify } from '../../../redux/actions/notifications/notificationActions';
import { Questions } from '../../../types';

interface Props {
  data: Questions;
}

const questionEdit = async (user: Questions) =>
  axios.put(`/Question/edit_question/${user._id}`, user);

const QuestionEdit: FunctionComponent<React.PropsWithChildren<Props>> = (
  props
) => {
  const { data } = props;

  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const enqueueSnackbar = useNotify();
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<Questions>({
    defaultValues: data,
    mode: 'onChange'
  });

  useEffect(() => {
    questionDropdownStr.find((subject) => subject.name === data.subject);
  }, [data.subject, data.topic]);

  const { mutate, isLoading } = useMutation(questionEdit, {
    onSuccess: (response) => {
      const { message } = response.data;
      dispatch(enqueueSnackbar({ message, options: { variant: 'success' } }));
      setTimeout(() => navigate(-1), 1000);
    },
    onError: (err: any) => {
      const message = err?.response.data || err.message;
      dispatch(
        enqueueSnackbar({
          message,
          options: { variant: 'error' }
        })
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(['Teams']);
      queryClient.invalidateQueries(['Pending']);
      queryClient.invalidateQueries(['question', params.id]);
    }
  });

  const onSubmit = (inputs: Omit<Questions, '_id'>) => {
    const user = {
      ...inputs,
      _id: data._id
    };
    mutate(user);
  };

  return (
    <Container style={{ marginTop: 28 }}>
      <Card>
        <CardHeader title="Edit Question" />
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              render={({ field: { onChange, value } }) => (
                <TextField
                  select
                  disabled
                  label="Type"
                  variant="outlined"
                  value={value}
                  onChange={onChange}
                  margin="normal"
                  size="small"
                  fullWidth
                  data-testid="type"
                >
                  <MenuItem value="Numerical" data-testid="numerical">
                    Numerical
                  </MenuItem>
                  <MenuItem value="Narrative" data-testid="Narrative">
                    Narrative
                  </MenuItem>
                  <MenuItem value="Text" data-testid="Text">
                    Text
                  </MenuItem>
                  <MenuItem value="Visual" data-testid="Visual">
                    Visual
                  </MenuItem>
                </TextField>
              )}
              rules={{ required: true }}
              name="type"
              control={control}
            />

            <TextField
              error={!!errors.question}
              label="Question"
              variant="outlined"
              fullWidth
              size="small"
              margin="normal"
              {...register('question')}
            />
            <TextField
              error={!!errors.correctAnswer}
              label="Correct Answer"
              variant="outlined"
              fullWidth
              size="small"
              margin="normal"
              {...register('correctAnswer')}
            />
            <TextField
              error={!!errors.incorrectAnswers}
              label="Incorrect Answers 1"
              variant="outlined"
              fullWidth
              margin="normal"
              size="small"
              type="text"
              defaultValue={data?.incorrectAnswers[0]}
              {...register('incorrectAnswers.0')}
            />
            <TextField
              error={!!errors.incorrectAnswers}
              label="Incorrect Answers 2"
              variant="outlined"
              fullWidth
              margin="normal"
              size="small"
              type="text"
              defaultValue={data?.incorrectAnswers[1]}
              {...register('incorrectAnswers.1')}
            />
            <TextField
              error={!!errors.incorrectAnswers}
              label="Incorrect Answers 3"
              variant="outlined"
              fullWidth
              margin="normal"
              size="small"
              type="text"
              defaultValue={data?.incorrectAnswers[2]}
              {...register('incorrectAnswers.2')}
            />

            <DialogActions>
              <Button
                disabled={isLoading}
                variant="contained"
                color="primary"
                startIcon={
                  isLoading ? (
                    <CircularProgress color="inherit" size={24} />
                  ) : null
                }
                type="submit"
              >
                Submit
              </Button>
            </DialogActions>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
};

export default QuestionEdit;

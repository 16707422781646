import { Grid } from '@mui/material';
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { axios } from '../../../clientProvider';
import Loading from '../../../components/Loading';
import { Questions } from '../../../types';
import QuestionEdit from './QuestionEdit';

const getAdminById = async (userId: string | undefined): Promise<Questions> => {
  const { data } = await axios.get(`/Question/view_question/${userId}`);
  return data.data;
};

function QuestionEditView() {
  const params = useParams();
  const { isLoading, data } = useQuery(['question', params.id], () =>
    getAdminById(params.id)
  );

  if (isLoading) return <Loading size={40} />;
  if (!data) return null;
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <QuestionEdit data={data} />
        </Grid>
      </Grid>
    </div>
  );
}

export default QuestionEditView;

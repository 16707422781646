/* eslint-disable import/prefer-default-export */
import React, { Suspense } from 'react';
import Loading from '../../components/Loading';
import SchoolDashboardLayout from '../../layouts/SchoolLayout';
import SchoolAuthRoute from '../../components/SchoolAuthRoute';
import SchoolDashboard from './dashboard';
import Teachers from './teachers';
import Students from './students';
import Classes from './classes';
import Grade from './grades';

export const SchoolRoutes = [
  {
    path: 'school',
    element: (
      <Suspense fallback={<Loading size={45} />}>
        <SchoolDashboardLayout />
      </Suspense>
    ),
    children: [
      {
        path: 'dashboard',
        element: (
          <Suspense fallback={<Loading size={45} />}>
            <SchoolAuthRoute component={SchoolDashboard} />
          </Suspense>
        )
      },
      {
        path: 'classes',
        element: (
          <Suspense fallback={<Loading size={45} />}>
            <SchoolAuthRoute component={Classes} />
          </Suspense>
        )
      },
      {
        path: 'grades',
        element: (
          <Suspense fallback={<Loading size={45} />}>
            <SchoolAuthRoute component={Grade} />
          </Suspense>
        )
      },
      {
        path: 'teachers',
        element: (
          <Suspense fallback={<Loading size={45} />}>
            <SchoolAuthRoute component={Teachers} />
          </Suspense>
        )
      },
      {
        path: 'students',
        element: (
          <Suspense fallback={<Loading size={45} />}>
            <SchoolAuthRoute component={Students} />
          </Suspense>
        )
      }
    ]
  }
];

/* eslint-disable import/prefer-default-export */
import { Chip } from '@mui/material';
import React from 'react';

export const useGradeColumn = () => {
  return [
    {
      name: '_id',
      label: 'ID',
      options: {
        filter: false,
        display: 'false'
      }
    },
    {
      name: 'grade',
      label: 'Grade',
      options: {
        filter: false,
        sort: false
      }
    },

    {
      name: 'isActive',
      label: 'isActive',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => <Chip label={value ? 'Yes' : 'No'} />
      }
    }
  ];
};

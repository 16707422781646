import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  CircularProgress,
  MenuItem

  // MenuItem
} from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';

import TextField from '@mui/material/TextField';

import { useFormik } from 'formik';
// import { useQueryClient } from '@tanstack/react-query';
// import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DateField } from '@mui/x-date-pickers/DateField';
import { useQueryClient } from '@tanstack/react-query';

import { RootState } from '../../../redux/reducers/rootReducer';

import { useAddClass } from '../../../clientProvider/mutations/classes-mutation';
import { useClasses } from '../../../clientProvider/queries/classes-querie';
import { ClassesValidator } from '../../../validators/classes-validator';
import { useGrades } from '../../../clientProvider/queries/grades-queries';

type AddStudentProps = {
  open: boolean;
  handleClickOpen: Dispatch<SetStateAction<boolean>>;
};
function AddStudent({ open, handleClickOpen }: AddStudentProps) {
  const { user } = useSelector((state: RootState) => state.user);
  const gradesQuery = useGrades({
    variables: { id: user?.school?._id as string }
  });
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const classesQuery = useClasses({
    variables: { id: user?.school?._id as string }
  });
  const { isLoading, mutateAsync } = useAddClass({
    onSuccess: () => {
      enqueueSnackbar('Class added', { variant: 'success' });
      setTimeout(() => handleClick(), 1500);
    },
    onError: (error) => {
      console.log(error.response?.data);
      // enqueueSnackbar(error?.response?.data, { variant: 'error' });
    },
    onSettled: () => queryClient.invalidateQueries(classesQuery.queryKey)
  });
  const handleClick = () => {
    handleClickOpen((state) => !state);
  };
  const {
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    values,
    isValid
  } = useFormik({
    initialValues: {
      name: '',
      description: '',
      grade: ''
    },
    onSubmit: async (data, { resetForm }) => {
      const value = {
        id: data.grade,
        payload: data
      };
      await mutateAsync(value);

      resetForm();
    },
    validationSchema: ClassesValidator,
    validateOnChange: true
  });

  if (gradesQuery.isLoading) return <CircularProgress size={25} />;
  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClick}>
      <form noValidate onSubmit={handleSubmit}>
        <DialogTitle>Add New Class</DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              m: 'auto'
            }}
          >
            <TextField
              margin="normal"
              size="small"
              fullWidth
              label="Class name"
              variant="filled"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!(touched.name && errors.name)}
              helperText={touched.name && errors.name}
              value={values.name}
            />

            <TextField
              margin="normal"
              size="small"
              fullWidth
              label="Grade"
              variant="filled"
              name="grade"
              onChange={handleChange}
              onBlur={handleBlur}
              select
              error={!!(touched.grade && errors.grade)}
              helperText={touched.grade && errors.grade}
              value={values.grade}
            >
              {gradesQuery?.data?.grades.map((grade) => (
                <MenuItem key={grade._id} value={grade._id}>
                  {grade.grade}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              margin="normal"
              size="small"
              fullWidth
              label="Description"
              variant="filled"
              name="description"
              multiline
              rows={4}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!(touched.description && errors.description)}
              helperText={touched.description && errors.description}
              value={values.description}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={isLoading}
            type="submit"
            variant="contained"
            color="primary"
            disabled={!isValid}
          >
            Add
          </LoadingButton>
          <Button onClick={handleClick}>Close</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default AddStudent;

/* eslint-disable import/prefer-default-export */
import { createMutation } from 'react-query-kit';
import { AxiosError } from 'axios';
import { axios } from '..';

type Variables = {
  id: string;
  payload: {
    name: string;
    description?: string;
    grade: string;
  };
};
export const useAddClass = createMutation<any, Variables, AxiosError>({
  mutationFn: async (variables) =>
    axios
      .post(`/School/classes/new_class/${variables.id}`, variables.payload)
      .then((response) => response.data)
});

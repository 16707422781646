/* eslint-disable react/function-component-definition */
import React, { FC } from 'react';
import { Container } from '@mui/material';
import QuestionsList from './components/QuestionsList';

const QuestionsView: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Container maxWidth={false}>
      <QuestionsList />
    </Container>
  );
};
export default QuestionsView;

const topics = [
  {
    topicCode: 1.1,
    topicCodeSimplified: 1,
    topicDescription: 'Numbers and notation'
  },
  {
    topicCode: 1.2,
    topicCodeSimplified: 2,
    topicDescription: 'Prime numbers'
  },
  {
    topicCode: 1.3,
    topicCodeSimplified: 3,
    topicDescription: 'Number bases'
  },
  {
    topicCode: 1.4,
    topicCodeSimplified: 4,
    topicDescription: 'Fractions, decimals and percentages'
  },
  {
    topicCode: 1.5,
    topicCodeSimplified: 5,
    topicDescription: 'Number patterns and sequences'
  },
  {
    topicCode: 1.6,
    topicCodeSimplified: 6,
    topicDescription: 'Addition'
  },
  {
    topicCode: 1.7,
    topicCodeSimplified: 7,
    topicDescription: 'Subtraction'
  },
  {
    topicCode: 1.8,
    topicCodeSimplified: 8,
    topicDescription: 'Multiplication'
  },
  {
    topicCode: 1.9,
    topicCodeSimplified: 9,
    topicDescription: 'Division'
  },
  {
    topicCode: 1.11,
    topicCodeSimplified: 11,
    topicDescription: 'Combined operations'
  },
  {
    topicCode: 1.12,
    topicCodeSimplified: 12,
    topicDescription: 'Ratio and proportion'
  },
  {
    topicCode: 1.14,
    topicCodeSimplified: 14,
    topicDescription: 'Indices'
  },
  {
    topicCode: 1.15,
    topicCodeSimplified: 15,
    topicDescription: 'Social and commercial arithmetic'
  },
  {
    topicCode: 2.16,
    topicCodeSimplified: 16,
    topicDescription: 'Sets'
  },
  {
    topicCode: 2.17,
    topicCodeSimplified: 17,
    topicDescription: 'Equations, inequations, expressions and formulae'
  },
  {
    topicCode: 2.19,
    topicCodeSimplified: 19,
    topicDescription: 'Matrices'
  },
  {
    topicCode: 2.2,
    topicCodeSimplified: 20,
    topicDescription: 'Graphs of functions'
  },
  {
    topicCode: 2.21,
    topicCodeSimplified: 21,
    topicDescription: 'Trigonometry'
  },
  {
    topicCode: 2.22,
    topicCodeSimplified: 22,
    topicDescription: 'Travel graphs'
  },
  {
    topicCode: 3.23,
    topicCodeSimplified: 23,
    topicDescription: 'Size, mass, capacity and temperature'
  },
  {
    topicCode: 3.24,
    topicCodeSimplified: 24,
    topicDescription: 'Distances and speed'
  },
  {
    topicCode: 3.25,
    topicCodeSimplified: 25,
    topicDescription: 'Money'
  },
  {
    topicCode: 3.26,
    topicCodeSimplified: 26,
    topicDescription: 'Time'
  },
  {
    topicCode: 4.28,
    topicCodeSimplified: 28,
    topicDescription: 'Two dimensional shapes'
  },
  {
    topicCode: 4.29,
    topicCodeSimplified: 29,
    topicDescription: 'Three dimensional shapes'
  },
  {
    topicCode: 4.3,
    topicCodeSimplified: 30,
    topicDescription: 'Angles'
  },
  {
    topicCode: 4.31,
    topicCodeSimplified: 31,
    topicDescription: 'Geometrical construction'
  },
  {
    topicCode: 4.32,
    topicCodeSimplified: 32,
    topicDescription: 'Bearings, position and direction'
  },
  {
    topicCode: 4.34,
    topicCodeSimplified: 34,
    topicDescription: 'Co-ordinate geometry'
  },
  {
    topicCode: 4.35,
    topicCodeSimplified: 35,
    topicDescription: 'Similarity and congruency'
  },
  {
    topicCode: 4.36,
    topicCodeSimplified: 36,
    topicDescription: 'Transformations'
  },
  {
    topicCode: 4.37,
    topicCodeSimplified: 37,
    topicDescription: 'Earth geometry'
  },
  {
    topicCode: 4.38,
    topicCodeSimplified: 38,
    topicDescription: 'Vectors'
  },
  {
    topicCode: 5.39,
    topicCodeSimplified: 39,
    topicDescription: 'Data, tables, graphs and charts'
  },
  {
    topicCode: 5.4,
    topicCodeSimplified: 40,
    topicDescription: 'Relations and functions'
  },
  {
    topicCode: 5.42,
    topicCodeSimplified: 42,
    topicDescription: 'Averages'
  },
  {
    topicCode: 5.43,
    topicCodeSimplified: 43,
    topicDescription: 'Probability'
  },
  {
    topicCode: 5.44,
    topicCodeSimplified: 44,
    topicDescription: 'Variation'
  },
  {
    topicCode: 5.45,
    topicCodeSimplified: 45,
    topicDescription: 'Frequency and dispersion'
  }
];

export default topics;

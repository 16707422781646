import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  MenuItem
  // MenuItem
} from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';

import TextField from '@mui/material/TextField';

import { useFormik } from 'formik';
// import { useQueryClient } from '@tanstack/react-query';
// import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DateField } from '@mui/x-date-pickers/DateField';
import { useQueryClient } from '@tanstack/react-query';

import { RootState } from '../../../redux/reducers/rootReducer';
import { StudentValidator } from '../../../validators/student-validator';
import { useAddStudent } from '../../../clientProvider/mutations/student-mutation';
import { useStudents } from '../../../clientProvider/queries/student-queries';

type AddStudentProps = {
  open: boolean;
  handleClickOpen: Dispatch<SetStateAction<boolean>>;
};
function AddStudent({ open, handleClickOpen }: AddStudentProps) {
  const { user } = useSelector((state: RootState) => state.user);

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const studentsQuery = useStudents({
    variables: { id: user?.school?._id as string }
  });
  const { isLoading, mutateAsync } = useAddStudent({
    onSuccess: () => {
      enqueueSnackbar('Student added', { variant: 'success' });
      setTimeout(() => handleClick(), 1500);
    },
    onError: (error) => {
      console.log(error.response?.data);
      // enqueueSnackbar(error?.response?.data, { variant: 'error' });
    },
    onSettled: () => queryClient.invalidateQueries(studentsQuery.queryKey)
  });
  const handleClick = () => {
    handleClickOpen((state) => !state);
  };
  const {
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    values,
    isValid
  } = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      gender: '',
      email: '',
      level: '',
      location: '',
      dob: new Date()
    },
    onSubmit: async (data, { resetForm }) => {
      const value = {
        id: user?.school?._id as string,
        payload: data
      };
      await mutateAsync(value);

      resetForm();
    },
    validationSchema: StudentValidator,
    validateOnChange: true
  });
  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClick}>
      <form noValidate onSubmit={handleSubmit}>
        <DialogTitle>Add New Student</DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              m: 'auto'
            }}
          >
            <TextField
              margin="normal"
              size="small"
              fullWidth
              label="First name"
              variant="filled"
              name="firstName"
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
              value={values.firstName}
            />
            <TextField
              margin="normal"
              size="small"
              fullWidth
              label="Last name"
              variant="filled"
              name="lastName"
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
              value={values.lastName}
            />
            <TextField
              margin="normal"
              size="small"
              fullWidth
              label="Email"
              variant="filled"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!(touched.email && errors.email)}
              helperText={touched.email && errors.email}
              value={values.email}
            />
            <TextField
              margin="normal"
              size="small"
              fullWidth
              label="Location"
              variant="filled"
              name="location"
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!(touched.location && errors.location)}
              helperText={touched.location && errors.location}
              value={values.location}
            />
            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DateField']}>
                <DateField
                  name="dob"
                  margin="normal"
                  size="small"
                  fullWidth
                  variant="filled"
                  label="Date of birth"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // error={!!(touched.dob && errors.dob)}
                  // @ts-ignore
                  helperText={touched.dob && errors.dob}
                  value={values.dob}
                />
              </DemoContainer>
            </LocalizationProvider> */}
            <TextField
              margin="normal"
              size="small"
              fullWidth
              label="Level"
              variant="filled"
              name="level"
              onChange={handleChange}
              onBlur={handleBlur}
              select
              error={!!(touched.level && errors.level)}
              helperText={touched.level && errors.level}
              value={values.level}
            >
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
            </TextField>
            <TextField
              margin="normal"
              size="small"
              fullWidth
              label="Gender"
              variant="filled"
              name="gender"
              onChange={handleChange}
              onBlur={handleBlur}
              select
              error={!!(touched.gender && errors.gender)}
              helperText={touched.gender && errors.gender}
              value={values.gender}
            >
              <MenuItem value="Male">Male</MenuItem>
              <MenuItem value="Female">Female</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </TextField>
          </Box>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={isLoading}
            type="submit"
            variant="contained"
            color="primary"
            disabled={!isValid}
          >
            Add
          </LoadingButton>
          <Button onClick={handleClick}>Close</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default AddStudent;

/* eslint-disable react/function-component-definition */
import * as React from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
  onlineManager
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useSnackbar } from 'notistack';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { useSelector } from 'react-redux';
import GlobalStyles from './components/GlobalStyles';
import theme from './theme';
import routes from './routes';
import Notifier from './Notifier';
import { RootState } from './redux/reducers/rootReducer';
import { useInterCeptor } from './clientProvider/baseConfig';

const App: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { user } = useSelector((store: RootState) => store.user);
  useInterCeptor(user?.token);
  const routing = useRoutes(routes);
  const { enqueueSnackbar } = useSnackbar();
  const isOnline = onlineManager.isOnline();
  const [queryClient] = React.useState<QueryClient>(
    new QueryClient({
      /**
       * Network mode (https://react-query-alpha.tanstack.com/guides/network-mode)
       */
      defaultOptions: {
        queries: {
          retry: 1,
          networkMode: 'offlineFirst'
        },
        mutations: {
          networkMode: 'offlineFirst'
        }
      },

      queryCache: new QueryCache({
        onError: (error, query) => {
          /**
           * Only show error toasts if we already have data in the cache
           * which indicates a failed background update
           */
          // @ts-ignore
          if (!query?.state?.error?.response?.status === 404) {
            if (query.state.data === undefined) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              enqueueSnackbar(`Something went wrong: ${error?.message}`, {
                variant: 'error'
              });
            }
          }
          // } else if (query.state.data === undefined) {
          //   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //   // @ts-ignore
          //   enqueueSnackbar(`Something went wrong: ${error?.message}`, {
          //     variant: 'error'
          //   });
          // }
        }
      })
    })
  );

  /**
   * Check if the user is online and if not, show a toast
   */
  if (!isOnline) {
    enqueueSnackbar(
      'You are offline. Please connect to the internet to use this app.',
      {
        variant: 'error',

        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        autoHideDuration: 5000
      }
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <ThemeProvider theme={theme}>
          <Notifier />
          <GlobalStyles />
          {routing}
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryParamProvider>
    </QueryClientProvider>
  );
};

export default App;

/* eslint-disable import/prefer-default-export */

import MUIDataTable from 'mui-datatables';
import React from 'react';
import { useSelector } from 'react-redux';
import { useClassesColumn } from './classes-columns';
// import { useTeacher } from '../../../clientProvider/queries/teacher-queries';
import { RootState } from '../../../redux/reducers/rootReducer';

import { useClasses } from '../../../clientProvider/queries/classes-querie';

export function ClassesList() {
  const columns = useClassesColumn();
  const { user } = useSelector((state: RootState) => state.user);

  const { data } = useClasses({
    variables: { id: user?.school?._id as string }
  });

  console.log('Clases', data);

  return (
    <MUIDataTable
      options={{
        elevation: 0,
        selectableRows: 'none',
        tableBodyHeight: '500px'
      }}
      // title="users"
      columns={columns}
      data={[]}
    />
  );
}

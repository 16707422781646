/* eslint-disable import/prefer-default-export */
import { Chip } from '@mui/material';
import React from 'react';

export const useStudentColumn = () => {
  return [
    {
      name: '_id',
      label: 'ID',
      options: {
        filter: false,
        display: 'false'
      }
    },
    {
      name: 'username',
      label: 'User name',
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: 'firstName',
      label: 'First name',
      options: {
        filter: false,
        sort: false
      }
    },

    {
      name: 'lastName',
      label: 'Last name',
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: 'isActive',
      label: 'isActive',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => <Chip label={value ? 'Yes' : 'No'} />
      }
    }
  ];
};

/* eslint-disable react/function-component-definition */
import React, { FunctionComponent, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { nanoid } from '@reduxjs/toolkit';
import { useNotify } from '../../../../redux/actions/notifications/notificationActions';
import axios from '../../../../clientProvider/baseConfig';

export interface WorkProps {
  message: string;
  total: number;
  data: ExcelProps[];
}

export interface ExcelProps {
  name: string;
  rowCount: number;
  columnCount: number;
}

interface Props {
  handleClose: () => void;
}

const getWorkerTabs = async () => {
  const { data: response } = await axios.get(
    '/Question/service/sheety/get_available_tabs'
  );
  return response.data;
};

const SkippedDetailsDialog: FunctionComponent<{
  skipped: { row: number; reason: string }[];
  total: number;
  totalSkipped: number;
  open: boolean;
  onClose: () => void;
}> = ({ skipped, total, totalSkipped, open, onClose }) => (
  <Dialog open={open} onClose={onClose} maxWidth="sm">
    <DialogContent>
      <Typography>Total Questions Imported: {total}</Typography>
      <Typography>Total Questions skipped: {totalSkipped}</Typography>
      <Divider />
      <DialogContentText>Skipped Questions Details:</DialogContentText>
      <List>
        {skipped.map((item) => (
          <ListItem key={item.row}>
            <ListItemText>
              <strong>Row {item.row}:</strong> {item.reason}
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

const SyncExcelWorker: FunctionComponent<React.PropsWithChildren<Props>> = (
  props
) => {
  const { handleClose } = props;
  const dispatch = useDispatch();
  const enqueueSnackbar = useNotify();
  const queryClient = useQueryClient();
  const [selectedItem, setSelectedItem] = useState('');
  const [skippedDialogOpen, setSkippedDialogOpen] = useState(false);
  const [skippedDetails, setSkippedDetails] = useState<
    { row: number; reason: string }[]
  >([]);
  const [totalQA, setTotal] = useState(0);
  const [totalSkippedQA, setTotalSkipped] = useState(0);

  const { control } = useForm<WorkProps>({ mode: 'onChange' });
  const { data: excelTabs } = useQuery(['ExcelWorker'], getWorkerTabs);

  const syncWorker = async () => {
    const response = await axios.post('/Question/service/sheety', {
      sheetName: selectedItem
    });
    return response;
  };

  const { mutate, isLoading } = useMutation(syncWorker, {
    onSuccess: (response) => {
      const { message, total, totalSkipped, skipped } = response.data;
      setTimeout(() => {
        dispatch(
          enqueueSnackbar({
            message,
            options: { variant: 'success' }
          })
        );
      }, 1500);

      if (skipped.length > 0) {
        setSkippedDetails(skipped);
        setSkippedDialogOpen(true);
        setTotalSkipped(totalSkipped);
        setTotal(total);
      }
    },
    onError: (err: any) => {
      dispatch(
        enqueueSnackbar({
          message: err?.response?.data.message,
          options: { variant: 'error' }
        })
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(['ExcelWorker']);
      queryClient.invalidateQueries(['Teams']);
    }
  });

  const handleSkippedDialogClose = () => {
    setSkippedDialogOpen(false);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Controller
          render={() => (
            <TextField
              select
              label="Select Excel Tab"
              variant="outlined"
              value={selectedItem}
              onChange={(e) => setSelectedItem(e.target.value)}
              margin="normal"
              size="small"
              fullWidth
              data-testid="name"
            >
              {excelTabs?.map((tab) => (
                <MenuItem key={nanoid(6)} value={tab.name}>
                  {tab.name}
                </MenuItem>
              ))}
            </TextField>
          )}
          rules={{ required: true }}
          name="data"
          control={control}
        />
      </Grid>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => mutate()}
          startIcon={
            isLoading ? <CircularProgress color="inherit" size={26} /> : null
          }
        >
          Submit
        </Button>
        <Button variant="outlined" onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>

      <SkippedDetailsDialog
        skipped={skippedDetails}
        open={skippedDialogOpen}
        onClose={handleSkippedDialogClose}
        total={totalQA} // Pass total as a prop
        totalSkipped={totalSkippedQA} // Pass totalSkipped as a prop
      />
    </Grid>
  );
};

export default SyncExcelWorker;

import { useState } from 'react';
import { axios } from '../../clientProvider';

type T_Curriculum = {
  lastUpdatedAt: Date;
  curriculum: String;
};

const useCurriculum = () => {
  const [uploadCurriculumVisibility, setUploadCurriculumVisibility] =
    useState<boolean>(false);
  const [curriculums, setCurriculums] = useState<T_Curriculum[]>([]);

  const openUploadCurriculumModal = () => setUploadCurriculumVisibility(true);

  const closeUploadCurriculumModal = () => setUploadCurriculumVisibility(false);

  const fetchCurriculums = async () => {
    const { data } = await axios.get(`/Curriculum/getCurriculumList`);

    setCurriculums(data.curriculums);

    return data.curriculums;
  };

  return {
    uploadCurriculumVisibility,
    openUploadCurriculumModal,
    closeUploadCurriculumModal,
    fetchCurriculums,
    curriculums
  };
};

export default useCurriculum;

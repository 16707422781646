/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-wrap-multilines */
import React, { FunctionComponent, useState } from 'react';
import {
  Button,
  CircularProgress,
  DialogActions,
  MenuItem,
  // MenuItem,
  TextField
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNotify } from '../../../redux/actions/notifications/notificationActions';
import axios from '../../../clientProvider/baseConfig';
import questionDropdownStr from '../../../components/questionDropdownStr';

interface Inputs {
  question: string;
  type: string;
  level: string;
  correctAnswer: string;
  incorrectAnswers1: string;
  incorrectAnswers2: string;
  incorrectAnswers3: string;
  subject: string;
  topic: string;
}
interface Props {
  handleClose: () => void;
}

type Subject = {
  id: number;
  name: string;
  topics: string[];
};

const createQuestion = async (user: Inputs) => {
  const response = await axios.post('/Question/new_question', user);
  return response;
};

const TeamForm: FunctionComponent<React.PropsWithChildren<Props>> = (props) => {
  const [selectedSubject, setSelectedSubject] = useState<Subject>();
  const [selectedTopic, setSelectedTopic] = useState('');
  const [topics, setTopics] = useState<string[]>([]);

  const { handleClose } = props;
  const dispatch = useDispatch();
  const enqueueSnackbar = useNotify();
  const queryClient = useQueryClient();
  const {
    handleSubmit,
    register,
    control,
    formState: { errors }
  } = useForm<Inputs>({ mode: 'onChange' });

  const handleChangeSubject = (event) => {
    const subjectName = event.target.value;
    const foundSubject = questionDropdownStr.find(
      (subject) => subject.name === subjectName
    );
    setSelectedSubject(foundSubject);
    setTopics(foundSubject ? foundSubject.topics : []);
    setSelectedTopic('');
  };

  //   const handleChangeTopic = (event) => {
  //     setSelectedTopic(event.target.value);
  //   };

  const { mutate, isLoading } = useMutation(createQuestion, {
    onSuccess: (response) => {
      const { message } = response.data;
      dispatch(enqueueSnackbar({ message, options: { variant: 'success' } }));
      setTimeout(() => handleClose(), 500);
    },
    onError: (err: any) => {
      dispatch(
        enqueueSnackbar({
          message: err?.response?.data.message,
          options: { variant: 'error' }
        })
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(['Teams']);
      queryClient.invalidateQueries(['Pending']);
    }
  });

  const onSubmit = (data: Inputs) => {
    const user = {
      ...data,
      incorrectAnswers: [
        data.incorrectAnswers1,
        data.incorrectAnswers2,
        data.incorrectAnswers3
      ]
    };
    mutate(user);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        error={!!errors.level}
        label="Level"
        variant="outlined"
        fullWidth
        margin="normal"
        size="small"
        type="number"
        {...register('level')}
      />
      <Controller
        render={({ field: { onChange } }) => (
          <TextField
            select
            label="Subject"
            variant="outlined"
            value={selectedSubject?.name ?? ''}
            onChange={(event) => {
              onChange(event);
              handleChangeSubject(event);
            }}
            margin="normal"
            size="small"
            fullWidth
            data-testid="subject"
          >
            {questionDropdownStr.map((subject) => (
              <MenuItem key={subject.id} value={subject.name}>
                {subject.name}
              </MenuItem>
            ))}
          </TextField>
        )}
        rules={{ required: true }}
        name="subject"
        control={control}
      />
      <Controller
        render={({ field: { onChange } }) => (
          <TextField
            select
            label="Topic"
            variant="outlined"
            value={selectedTopic}
            onChange={(event) => {
              onChange(event);
              setSelectedTopic(event.target.value);
            }}
            margin="normal"
            size="small"
            fullWidth
            data-testid="topic"
          >
            {topics.map((topic) => (
              <MenuItem key={topic} value={topic}>
                {topic}
              </MenuItem>
            ))}
          </TextField>
        )}
        rules={{ required: true }}
        name="topic"
        control={control}
      />
      <Controller
        render={({ field: { onChange, value } }) => (
          <TextField
            select
            label="Type"
            variant="outlined"
            value={value}
            onChange={onChange}
            margin="normal"
            size="small"
            fullWidth
            data-testid="type"
          >
            <MenuItem value="Numerical" data-testid="numerical">
              Numerical
            </MenuItem>
            <MenuItem value="Narrative" data-testid="Narrative">
              Narrative
            </MenuItem>
            <MenuItem value="Text" data-testid="Text">
              Text
            </MenuItem>
            <MenuItem value="Visual" data-testid="Visual">
              Visual
            </MenuItem>
          </TextField>
        )}
        rules={{ required: true }}
        name="type"
        control={control}
      />
      <TextField
        error={!!errors.question}
        label="Question"
        variant="outlined"
        fullWidth
        size="small"
        margin="normal"
        {...register('question')}
      />
      <TextField
        error={!!errors.correctAnswer}
        label="Correct Answer"
        variant="outlined"
        fullWidth
        size="small"
        margin="normal"
        {...register('correctAnswer')}
      />
      <TextField
        error={!!errors.incorrectAnswers1}
        label="Incorrect Answers 1"
        variant="outlined"
        fullWidth
        margin="normal"
        size="small"
        type="text"
        {...register('incorrectAnswers1')}
      />
      <TextField
        error={!!errors.incorrectAnswers2}
        label="Incorrect Answers 2"
        variant="outlined"
        fullWidth
        margin="normal"
        size="small"
        type="text"
        {...register('incorrectAnswers2')}
      />
      <TextField
        error={!!errors.incorrectAnswers3}
        label="Incorrect Answers 3"
        variant="outlined"
        fullWidth
        margin="normal"
        size="small"
        type="text"
        {...register('incorrectAnswers3')}
      />

      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          startIcon={
            isLoading ? <CircularProgress color="inherit" size={26} /> : null
          }
        >
          Submit
        </Button>
        <Button variant="outlined" onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </form>
  );
};

export default TeamForm;

/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-key */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, List, ListItemIcon, ListItemText } from '@mui/material';
import React from 'react';
import { v4 as uuid } from 'uuid';
// @ts-ignore
import CustomLink from '../../CustomLink';

export interface NavItemProps {
  href: string;
  icon: any;
  title: string;
  nestedItems?: NavItemProps[];
}
interface Props {
  items: NavItemProps[];
  className?: string;
}
const NavItem: React.FunctionComponent<Props> = ({ items, className }) => {
  console.log(className);
  const [selectedIndex, setSelectedIndex] = React.useState<null | number>(null);

  const handleClickIndex = (index: number) => {
    if (selectedIndex === index) {
      setSelectedIndex(null);
    } else {
      setSelectedIndex(index);
    }
  };

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      sx={{ width: '100%', maxWidth: 360, backgroundColor: '#ffffff' }}
    >
      {items.map(({ title, icon: Icon, nestedItems, href }, index: any) => (
        <>
          <CustomLink
            key={uuid()}
            index={index}
            nestedItems={nestedItems}
            handleClickIndex={handleClickIndex}
            to={href}
          >
            <ListItemIcon>
              {/* @ts-ignore */}
              <Icon />
            </ListItemIcon>
            <ListItemText primary={title} />
            {nestedItems ? (
              index === selectedIndex ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )
            ) : null}
          </CustomLink>
          {nestedItems
            ? nestedItems.map((item) => (
                <Collapse
                  in={index === selectedIndex}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    <CustomLink
                      handleClickIndex={handleClickIndex}
                      index={index}
                      style={{
                        paddingLeft: '40px'
                      }}
                      to={item.href}
                    >
                      <ListItemIcon style={{ marginLeft: '20px' }}>
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText primary={item.title} />
                    </CustomLink>
                  </List>
                </Collapse>
              ))
            : null}
        </>
      ))}
    </List>
  );
};
export default NavItem;

import * as React from 'react';
import { CustomModal } from '../../../../components/Modal';
import UploadCurriculumButton from '../UploadCurriculumButton';
import AdminPageWrapper from '../../../../layouts/AdminLayout/admin-page-wrapper';
import UploadCurriculumModal from '../UploadCurriculumModal';

function UploadCurriculumView() {
  const [openDailyModal, setOpenDailyModal] = React.useState<boolean>(false);

  const handleClickOpenDaily = () => {
    setOpenDailyModal(true);
  };
  const handleClickCloseDaily = () => {
    setOpenDailyModal(false);
  };

  const rightNodes = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
        justifyContent: 'flex-end'
      }}
    >
      <UploadCurriculumButton handleClickOpenDaily={handleClickOpenDaily} />
    </div>
  );

  return (
    <AdminPageWrapper pageTitle="Curriculum" rightNodes={rightNodes}>
      <div style={{ marginTop: '10px' }}>
        <CustomModal
          title="Upload Curriculum"
          open={openDailyModal}
          maxWidth="sm"
          handleClose={handleClickCloseDaily}
        >
          <UploadCurriculumModal handleClose={handleClickCloseDaily} />
        </CustomModal>
      </div>
    </AdminPageWrapper>
  );
}

export default UploadCurriculumView;

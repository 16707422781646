import React from 'react';
import clsx from 'clsx';
import { CloudUpload } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  root: { marginTop: 22, marginBottom: 22 },
  importButton: {
    marginRight: 10
  },
  exportButton: {
    marginRight: 10
  }
}));

interface ToolbarProps {
  className?: string;
  handleClickOpenDaily: () => void;
}

function UploadCurriculumButton({
  className,
  handleClickOpenDaily,
  ...rest
}: ToolbarProps) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box display="flex" justifyContent="flex-end">
        <Button
          startIcon={<CloudUpload />}
          onClick={handleClickOpenDaily}
          style={{
            backgroundColor: '#E85D04'
          }}
          variant="contained"
        >
          Upload Curriculum
        </Button>
      </Box>
    </div>
  );
}

export default UploadCurriculumButton;

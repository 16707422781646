import { createQuery } from 'react-query-kit';
import { AxiosError } from 'axios';
import { axios } from '..';

type TResponse = {
  message: string;
  total: number;
  grades: {
    _id: string;
    grade: string;
    school: string;
    gender: string;
    isActive: boolean;
    awards: any[];
    createdAt: string;
    updatedAt: string;
    __v: number;
  }[];
};

type TData = {
  id: string;
};

// eslint-disable-next-line import/prefer-default-export
export const useGrades = createQuery<TResponse, TData, AxiosError>({
  primaryKey: '/grades',
  queryFn: ({ queryKey: [, variables] }) =>
    axios
      .get(`/School/grades/view_grades/${variables.id}`)
      .then((response) => response.data)
});

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  MenuItem,
  CircularProgress
  // MenuItem
} from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';

import TextField from '@mui/material/TextField';

import { useFormik } from 'formik';
// import { useQueryClient } from '@tanstack/react-query';
// import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DateField } from '@mui/x-date-pickers/DateField';
import { useQueryClient } from '@tanstack/react-query';

import { RootState } from '../../../redux/reducers/rootReducer';
import { useTeacher } from '../../../clientProvider/queries/teacher-queries';
import { useGrades } from '../../../clientProvider/queries/grades-queries';
import { useAddGrade } from '../../../clientProvider/mutations/grades-mutation';
import { GradesValidator } from '../../../validators/grades-validator';

function capitalizeFirstLetter(word) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

type AddStudentProps = {
  open: boolean;
  handleClickOpen: Dispatch<SetStateAction<boolean>>;
};
function AddGrade({ open, handleClickOpen }: AddStudentProps) {
  const { user } = useSelector((state: RootState) => state.user);

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const gradesQuery = useGrades({
    variables: { id: user?.school?._id as string }
  });

  const teacherQuery = useTeacher({
    variables: { id: user?.school?._id as string }
  });

  const { isLoading, mutateAsync } = useAddGrade({
    onSuccess: () => {
      enqueueSnackbar('Grade added', { variant: 'success' });
      setTimeout(() => handleClick(), 1500);
    },
    onError: (error) => {
      console.log(error.response?.data);
      enqueueSnackbar(error?.response?.data.message, { variant: 'error' });
    },
    onSettled: () => queryClient.invalidateQueries(gradesQuery.queryKey)
  });
  const handleClick = () => {
    handleClickOpen((state) => !state);
  };
  const {
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    values,
    isValid
  } = useFormik({
    initialValues: {
      grade: '',
      teacher: ''
    },
    onSubmit: async (data, { resetForm }) => {
      const value = {
        id: user?.school?._id as string,
        payload: data
      };
      await mutateAsync(value);

      resetForm();
    },
    validationSchema: GradesValidator,
    validateOnChange: true
  });

  if (teacherQuery.isLoading) return <CircularProgress size={25} />;
  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClick}>
      <form noValidate onSubmit={handleSubmit}>
        <DialogTitle>Add New Grade</DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              m: 'auto'
            }}
          >
            <TextField
              margin="normal"
              size="small"
              fullWidth
              label="Grade"
              variant="filled"
              name="grade"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!(touched.grade && errors.grade)}
              helperText={touched.grade && errors.grade}
              value={values.grade}
            />

            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DateField']}>
                <DateField
                  name="dob"
                  margin="normal"
                  size="small"
                  fullWidth
                  variant="filled"
                  label="Date of birth"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // error={!!(touched.dob && errors.dob)}
                  // @ts-ignore
                  helperText={touched.dob && errors.dob}
                  value={values.dob}
                />
              </DemoContainer>
            </LocalizationProvider> */}
            <TextField
              margin="normal"
              size="small"
              fullWidth
              label="Teacher"
              variant="filled"
              name="teacher"
              onChange={handleChange}
              onBlur={handleBlur}
              select
              error={!!(touched.teacher && errors.teacher)}
              helperText={touched.teacher && errors.teacher}
              value={values.teacher}
            >
              {teacherQuery.data?.data.map((teacher) => (
                <MenuItem key={teacher._id} value={teacher._id}>
                  {`${capitalizeFirstLetter(
                    teacher.firstName
                  )} ${capitalizeFirstLetter(teacher.lastName)}`}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={isLoading}
            type="submit"
            variant="contained"
            color="primary"
            disabled={!isValid}
          >
            Add
          </LoadingButton>
          <Button onClick={handleClick}>Close</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default AddGrade;

import React from 'react';
import {
  Grid,
  Button,
  TextField,
  Typography,
  CircularProgress,
  InputAdornment,
  IconButton,
  Box
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { axios } from '../../clientProvider';
import { useNotify } from '../../redux/actions/notifications/notificationActions';

interface Inputs {
  password: string;
  confirmPassword?: string;
  resetPin: string;
}
const schema = yup.object().shape({
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password is too short - should be longer than 8 characters.')
    .max(32, 'Password must be less than 32 characters'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null, ''], "Passwords don't match")
    .required('Password confirmation is required')
});
function ResetPassword() {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const enqueueSnackbar = useNotify();
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    formState: { errors }
    // watch
  } = useForm<Inputs>({
    mode: 'onChange',
    resolver: yupResolver(schema)
  });

  const grantAccess = async (data: Inputs) => {
    const { data: response } = await axios.post(`/Auth/reset_password`, data);
    return response.data;
  };
  const { mutate, isLoading } = useMutation(grantAccess, {
    onSuccess: (response) => {
      console.log('reset', response);
      const message = 'Reset password successfully';
      dispatch(enqueueSnackbar({ message, options: { variant: 'success' } }));
      setTimeout(() => navigate('/'), 1000);
    },
    onError: (error: AxiosError) => {
      dispatch(
        enqueueSnackbar({
          message: error.response?.data.message,
          options: { variant: 'error' }
        })
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(['resetPassword']);
    }
  });
  const onSubmit = (data: Inputs) => {
    const reset = {
      ...data
    };
    mutate(reset);
  };
  return (
    <div
      style={{
        padding: 0,
        backgroundColor: '#ffffff',
        height: '100%'
      }}
    >
      <Grid
        style={{
          height: '100%'
        }}
        container
      >
        <Grid
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
          }}
          item
          xs={12}
          sm={5}
          md={5}
          lg={5}
          xl={5}
        >
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: {
                  md: 'center'
                }
              }}
            >
              <form
                style={{
                  width: 100,
                  paddingLeft: 40,
                  paddingRight: 40,
                  paddingBottom: 125,
                  flexBasis: 700
                }}
                onSubmit={handleSubmit(onSubmit)}
              >
                <Typography
                  sx={{
                    marginTop: 3,
                    textAlign: 'center'
                  }}
                  color="textSecondary"
                  gutterBottom
                >
                  <h2>Forgot password?</h2>
                  <br />
                  <h4>
                    Kindly enter the pin you received in your email and set a
                    new password below.
                  </h4>
                </Typography>

                <TextField
                  error={Boolean(errors.resetPin?.message)}
                  sx={{
                    marginTop: 2
                  }}
                  fullWidth
                  label="Verification Code"
                  type="text"
                  variant="outlined"
                  {...register('resetPin')}
                />
                <TextField
                  error={Boolean(errors.password?.message)}
                  sx={{
                    marginTop: 2
                  }}
                  fullWidth
                  label="Password"
                  variant="outlined"
                  {...register('password')}
                  type={showPassword ? 'text' : 'password'}
                  helperText={errors.password?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <TextField
                  error={Boolean(errors.confirmPassword?.message)}
                  sx={{
                    marginTop: 2
                  }}
                  fullWidth
                  label="Confirm Password"
                  variant="outlined"
                  {...register('confirmPassword')}
                  type={showPassword ? 'text' : 'password'}
                  helperText={errors.confirmPassword?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />

                <Button
                  sx={{
                    marginTop: 2
                  }}
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  disabled={isLoading}
                  startIcon={
                    isLoading ? (
                      <CircularProgress color="inherit" size={25} />
                    ) : null
                  }
                >
                  Submit
                </Button>
              </form>
            </Box>
          </div>
        </Grid>
        <Grid
          sx={{
            display: {
              xs: 'none',
              sm: 'none',
              md: 'block',
              lg: 'block',
              xl: 'block'
            }
          }}
          item
          xs={12}
          sm={7}
          md={7}
          lg={7}
          xl={7}
        >
          <div
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#E85D04',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center'
            }}
          >
            <div
              style={{
                height: '80%',
                width: '80%',
                // top: '10.88%',
                // position: 'absolute',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundImage: 'url(/images/APPACUS_white.png)',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
              }}
            />
            {/* <div
              style={{
                height: '283px',
                width: '283px',
                top: '36.83%',
                position: 'absolute',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundImage: 'url(/images/APPACUS_icon_color.png)',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
              }}
            /> */}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default ResetPassword;
